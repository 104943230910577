import React from "react";
import { graphql, Link } from "gatsby";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { IoOpenOutline, IoArrowForward } from "react-icons/io5";

import { Container, Wrapper, Row, Box } from "../components/util";
import { CallToAction, IframeContainer, Breadcrumb } from "../components/site/";
import Layout from "../components/layout";

import Seo from "../components/seo";

const RelatedCard = styled(Box)`
  h4 {
    a {
      color: ${(props) => props.theme.colours.dark};
      display: block;
      font-weight: 700;
      padding: 20px 45px 20px 20px;
      border-radius: ${(props) => props.theme.styles.borderRadius};
      background-color: ${(props) => props.theme.colours.white};
      border: solid 1px ${(props) => props.theme.colours.greyDarken};
      text-decoration: none;

      .gatsby-image-wrapper {
        border-radius: ${(props) => props.theme.styles.borderRadius};
      }

      &:hover {
        color: ${(props) => props.theme.colours.Primary};
        background-color: ${(props) => props.theme.colours.midGrey};
      }

      &:active {
        background-color: ${(props) => props.theme.colours.greyDarken};
      }

      svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    contentfulDefinition(url: { eq: $slug }) {
      title
      url
      question
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
      relatedDefinitions {
        ... on ContentfulDefinition {
          title
          url
        }
      }
      relatedResources {
        ... on ContentfulOutboundLink {
          title
          url
        }
        ... on ContentfulResource {
          resourceTitle
          url
          resourceTopic
          resourceImage {
            gatsbyImageData(width: 75, layout: FIXED, quality: 100)
          }
        }
      }
    }
  }
`;

const Definition = (props) => {
  const website_url = process.env.GATSBY_SITE_URL;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (
          node.data.target.link.startsWith(website_url) ||
          node.data.target.link.startsWith("/") ||
          node.data.target.link.startsWith("#")
        ) {
          return (
            <Link to={node.data.target.link}>
              <GatsbyImage
                className="-rounded -addShadow"
                image={node.data.target.image.gatsbyImageData}
                alt={node.data.target.imageAltText}
                title={node.data.target.imageAltText}
              />
            </Link>
          );
        } else {
          return (
            <a
              href={node.data.target.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                className="-rounded -addShadow"
                image={node.data.target.image.gatsbyImageData}
                alt={node.data.target.imageAltText}
                title={node.data.target.imageAltText}
              />
            </a>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
            title={node.data.target.title}
          />
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.startsWith("#ID=")) {
          return (
            <span
              className="post-anchor"
              css={{ opacity: "0" }}
              id={node.data.uri.replace("#ID=", "")}
            >
              &nbsp;
            </span>
          );
        } else if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <IframeContainer title="PayHero Vimeo Video" src={node.data.uri} />
          );
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <IframeContainer
              title="PayHero YouTube Video"
              src={node.data.uri}
            />
          );
        } else if (
          node.data.uri.startsWith(website_url) ||
          node.data.uri.startsWith("/") ||
          node.data.uri.startsWith("#")
        ) {
          return (
            <Link to={node.data.uri}>
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </Link>
          );
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </a>
          );
        }
      },
    },
  };

  const definition = props.data.contentfulDefinition;

  return (
    <Layout>
      <Seo
        title={definition.question + ` | PayHero`}
        description={renderRichText(
          definition.content
        )[0].props.children[0].substr(0, 155)}
      />
      <Breadcrumb
        parentPage="Payroll Glossary"
        parentPageLink="/payroll-glossary"
        currentPage={definition.title}
      />
      <Container>
        <Wrapper className="-center" maxWidth={900}>
          <h1>{definition.question}</h1>
          <Box stackGap={30}>
            <h2>{definition.title} (definition)</h2>
            <div className="font-bump">
              {renderRichText(definition.content, options)}
            </div>
          </Box>
          {definition.relatedDefinitions && (
            <>
              <Box stackGap={30}>
                <h4 className="-fontBold">Related Definitions</h4>
                <Box stackGap={15} className="font-bump">
                  {definition.relatedDefinitions.map((edge, i) => {
                    return (
                      <p key={i}>
                        <Link to={`/payroll-glossary/` + edge.url}>
                          {edge.title}
                        </Link>
                      </p>
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
          {definition.relatedResources && (
            <>
              <hr />
              <Box stackGap={30}>
                <h4 className="-fontBold">Related Resources</h4>
                <Box stackGap={10}>
                  {definition.relatedResources.map((edge, i) => {
                    return (
                      <RelatedCard key={i}>
                        <h4>
                          {edge.resourceTitle ? (
                            <Link to={`/blog/` + edge.url}>
                              <Row
                                stackGap={15}
                                justify="flex-start"
                                alignCenter
                                noBreak
                              >
                                <GatsbyImage
                                  image={edge.resourceImage.gatsbyImageData}
                                  alt={edge.resourceTitle}
                                  title={edge.resourceTitle}
                                />
                                <span>{edge.resourceTitle}</span>
                              </Row>
                              <IoArrowForward />
                            </Link>
                          ) : (
                            <a href={edge.url} target="_blank">
                              {edge.title}
                              <br />
                              <span
                                css={{
                                  fontSize: "0.9rem",
                                  color: "#999",
                                  fontWeight: "400",
                                }}
                              >
                                {edge.url}
                              </span>
                              <IoOpenOutline />
                            </a>
                          )}
                        </h4>
                      </RelatedCard>
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
        </Wrapper>
      </Container>
      <CallToAction
        title="Find a payroll expert or get started today"
        buttonText="Start a free PayHero trial today"
        showDirectoryButton
        hideDesc
      />
    </Layout>
  );
};

export default Definition;
